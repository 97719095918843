/*--------------------------------------------------------------
	#team single page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#5.1	team-sigle-section
--------------------------------------------------------------*/
.team-sigle-section {
	.team-single-sidebar {
		@include media-query(991px) {
			margin-bottom: 70px;
		}
	}

	.attorney-widget {
		background: #fafafa;
		border: 5px solid #f1f1f1;
		padding: 35px 30px;

		@include widther(1200px) {
			margin-right: 40px;
		}

		h3 {
			font-size: 24px;
			font-size: calc-rem-value(24);
			margin: 0 0 0.7em;
		}

		ul > li + li {
			border-top: 1px solid #e7e7e7;
		}

		a {
			display: block;
			font-weight: 600;
			color: #687693;
			padding: 13px 15px 13px 0;
		}

		a:hover,
		li.current a {
			color: $theme-primary-color;
		}
	}

	.attorney-single-content {

		.attorney {
			.img-holder {
				img{
					width: 100%;
					max-height: 500px;
					object-fit: cover;
					object-position: center top;
				}
			}
		}
		.attorney-single-info {
			padding: 60px;
			box-shadow: 0px 9px 42.32px 3.68px rgba(25, 38, 62, 0.06);

			@include media-query(991px) {
				padding: 45px;
			}

			@include media-query(767px) {
				padding: 30px 20px;
			}

			h3 {
				font-size: 24px;
				font-size: calc-rem-value(24);
				font-weight: 400;
				margin: 0 0 0.3em;

				@include media-query(767px) {
					font-size: 20px;
					font-size: calc-rem-value(20);
				}
			}

			h3 + span {
				font-weight: 600;
				color: $theme-primary-color;
			}

			.info ul {
				margin-top: 25px;
			}

			.info ul > li + li {
				margin-top: 8px;
			}

			.info li i {
				display: inline-block;
				color: $theme-primary-color;
				padding-right: 5px;
			}

			.info li span {
				font-weight: 600;
				color: $heading-color;
			}

			.social {
				margin-top: 30px;

				ul {
					overflow: hidden;
				}

				ul li {
					float: left;
				}

				ul > li + li {
					margin-left: 7px;

					@include media-query(767px) {
						margin-left: 3px;
					}
				}

				a {
					background-color: #ebebeb;
					width: 43px;
					height: 43px;
					line-height: 43px;
					font-size: 13px;
					color: #687693;
					text-align: center;
					border-radius: 50%;
					display: block;

					@include media-query(767px) {
						width: 38px;
						height: 38px;
						line-height: 38px;
					}
				}

				a:hover {
					color: $theme-primary-color;
				}

			}
		}

		.attorney-details {
			padding-top: 35px;

			h2 {
				font-size: 30px;
				font-size: calc-rem-value(30);
				margin: 1.3em 0 0.7em;

				@include media-query(991px) {
					font-size: 27px;
					font-size: calc-rem-value(27);
				}

				@include media-query(767px) {
					font-size: 24px;
					font-size: calc-rem-value(24);
				}
			}

			ul > li + li {
				margin-top: 7px;
			}
		}

		.skills {
			padding-top: 10px;
			padding-bottom: 20px;

			.skill {
				margin-bottom: 35px;

				&:last-child {
					margin-bottom: 0;

					.progress {
						margin-bottom: 0;
					}
				}
			}

			h6 {
				font-family: $base-font;
				font-size: 16px;
				font-size: calc-rem-value(16);
				font-weight: 600;
				margin: 0 0 0.6em;
				color: $text-color;

				@include media-query(991px) {
					font-size: 14px;
					font-size: calc-rem-value(14);
				}
			}

			.progress {
				background-color: #e6e6e6;
				height: 5px;
				box-shadow: none;
				overflow: visible;
				box-shadow: none;
				position: relative;
				border-radius: 3px;

				> span {
					font-family: $heading-font;
					font-size: 14px;
					font-size: calc-rem-value(14);
					font-weight: 500;
					color: $text-color;
					padding: 2px 6px;
					text-align: center;
					position: absolute;
					right: 20px;
					top: -32px;
					@include rounded-border(3px);

					@include media-query(991px) {
						padding: 0 6px;
					}
				}
			}

			.progress-bar {
				background: $theme-primary-color;
				position: relative;
				box-shadow: none;
			    border-radius: 3px;
				overflow: visible;

			    &:after {
			    	content: "";
			    	width: 10px;
			    	height: 10px;
			    	background-color: $theme-primary-color;
			    	border-radius: 50%;
			    	position: absolute;
			    	right: 0;
			    	top: -3px;
			    	box-shadow: 0 0 5px #969696;
			    }
			}
		}

		form input,
		form select,
		form textarea {
			background: #f5f5f5;
			width: 100%;
			height: 55px;
			border: 0;
			border-radius: 0;
			box-shadow: none !important;

			@include media-query(991px) {
				height: 45px;
			}

			&:focus {
				border-color: $theme-primary-color;
			}
		}

		form textarea {
			height: 180px;
		}

		form {
			margin: 0 -15px;
	    	overflow: hidden;

			@include placeholder-style(#9d9c9c, 15px, normal);

		    select {
		        display: inline-block;
		        color: #a9a9a9;
		        cursor:pointer;
		        opacity: 1;
		        padding: 6px 12px;
		        font-size: 15px;
		        font-size: calc-rem-value(15);
		    }
		}

		form > div {
			width: calc(50% - 30px);
		    float: left;
		    margin: 0 15px 30px;

		    @include media-query(600px) {
				width: calc(100% - 30px);
		    	float: none;
			}
		}

		form .fullwidth {
			width: calc(100% - 30px);
			float: none;
			clear: both;
		}

		form .submit-area {
			margin-bottom: 0;
		}




	}
}