/*--------------------------------------------------------------
#0.5	footer
--------------------------------------------------------------*/
.site-footer {
	background-color: $theme-secondary-color;
	background-size: cover;
	position: relative;
	font-size: 15px;
	overflow: hidden;

	ul {
		list-style: none;
	}

	p,
	li {
		color: #cdced0;
	}

	/*** social-newsletter-area***/
	.social-newsletter-area {
		background-color: darken($theme-secondary-color, 5%);
		padding: 55px 0 60px;

		.social-newsletter-content {

			@include media-query(991px) {
				text-align: center;
			}


			> div:first-child {
				width: 320px;
				float: left;

				@include media-query(991px) {
					width: 100%;
					float: none;
				}
			}

			> div:nth-child(2) {
				width: calc(100% - 640px);
				float: left;

				@include media-query(991px) {
					width: 100%;
					float: none;
					margin: 35px 0;
				}
			}

			> div:last-child {
				width: 320px;
				float: right;

				@include media-query(991px) {
					width: 100%;
					float: none;
				}
			}
		}

		.social-area ul {
			overflow: hidden;
			padding-top: 28px;

			@include media-query(991px) {
				display: inline-block;
				padding-top: 0;
			}

			li {
				float: left;
			}

			> li + li {
				margin-left: 10px;
			}

			a {
				background-color: lighten($theme-secondary-color, 5%);
				width: 50px;
				height: 50px;
				line-height: 50px;
				text-align: center;
				font-size: 14px;
				font-size: calc-rem-value(14);
				color: $white;
				display: block;
				border: 1px solid transparentize($white, 0.9);
				border-radius: 50px;

				&:hover {
					background-color: $theme-primary-color;
				}
			}
		}

		.logo-area {
			text-align: center;

			img {
				display: inline-block;
				margin-left: -90px;

				@include media-query(991px) {
					margin-left: 0;
				}
			}
		}

		.newsletter-area {

			.inner {
				max-width: 400px;

				@include media-query(991px) {
					margin: 0 auto;
				}
			}

			h3 {
				font-size: 20px;
				font-size: calc-rem-value(20);
				font-weight: 400;
				color: $white;
				margin: 0;
			}

			form {
				margin-top: 25px;
				position: relative;

				input {
					background-color: lighten($theme-secondary-color, 5%);
					height: 55px;
					color: $text-color;
					padding: 6px 20px;
					border-radius: 0;
					border: 0;
					box-shadow: none;
				}

				button {
					background-color: $theme-primary-color;
					width: 55px;
					height: 55px;
					position: absolute;
					right: 0;
					top: 0;
					border: 0;
					outline: 0;
					font-size: 20px;
					color: $white;
				}
			}
		}
	}



	.upper-footer {
		padding: 90px 0;

		@include media-query(991px) {
			padding: 90px 0 20px;
		}

		@include media-query(767px) {
			padding: 80px 0 10px;
		}

		.col {
			@include media-query(991px) {
				min-height: 235px;
				margin-bottom: 70px;
			}

			@include media-query(767px) {
				min-height: auto;
				margin-bottom: 60px;
			}
		}
	}

	.widget-title {
		margin-bottom: 30px;

		@include media-query(767px) {
			margin-bottom: 20px;
		}
	}

	.widget-title h3 {
		font-size: 20px;
		font-size: calc-rem-value(20);
		font-weight: 400;
		color: $white;
		margin: 0;
		padding-bottom: 0.4em;
		text-transform: capitalize;
		position: relative;

		@include media-query(991px) {
			padding-bottom: 0;
		}
	}

	/*** about-widget ***/
	.about-widget {
		p {
			margin-bottom: 0.8em;
			line-height: 1.9em;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}

	/*** link-widget ***/
	.link-widget {
		overflow: hidden;

		@include widther(1200px) {
			padding-left: 20px;
		}

		@include media-query(1199px) {
			padding-left: 20px;
		}

		@include media-query(991px) {
			padding-left: 0;
		}

		@include media-query( 767px ) {
	       max-width: 350px;
	    }

		ul {
			width: 50%;
			float: left;
		}

		ul li {
			position: relative;
		}

		ul a {
			color: #cdced0;
			font-weight: 500;
		}

		ul a:hover,
		ul li:hover:before {
			text-decoration: underline;
		}

		ul > li + li {
			margin-top: 15px;
		}
	}

	.line-widget-2 {
		ul {
			width: 100%;
			float: none;
		}
	}

	/*** contact-widget ***/
	.contact-widget {
		@include widther(1200px) {
			padding-left: 25px;
		}

		ul {
			margin-top: 25px;
		}

		ul li {
			position: relative;
		}

		ul > li + li {
			margin-top: 15px;
		}

		li span {
			font-weight: 500;
		}
	}


	/*** lower-footer ***/
	.lower-footer {
		text-align: center;
		position: relative;

		.row {
			padding: 20px 0;
			position: relative;

			.separator {
				background: transparentize($white, 0.9);
				width: calc(100% - 30px);
				height: 1px;
				position: absolute;
				left: 15px;
				top: 0;
			}
		}

		.copyright {
			display: inline-block;
			float: left;
			font-size: 14px;
			font-size: calc-rem-value(14);
			margin: 0;

			@include media-query(991px) {
				float: none;
				display: block;
				margin: 0 0 20px;
			}

			a {
				color: #cdced0;
				text-decoration: underline;
			}
		}

		.extra-link {
			display: inline-block;
			float: right;
			overflow: hidden;

			@include media-query(991px) {
				float: none;
				display: block;
				text-align: center;
			}
		}

		.extra-link ul {
			overflow: hidden;
			list-style: none;

			@include media-query(991px) {
				display: inline-block;
			}

			li {
				float: left;
			}

			> li + li {
				margin-left: 35px;
				position: relative;

				@include media-query(767px) {
					margin-left: 15px;
				}

				&:before {
					content: "";
					background: $heading-color;
					width: 15px;
					height: 1px;
					position: absolute;
					left: -25px;
					top: 11px;

					@include media-query(767px) {
						display: none;
					}
				}
			}

			a {
				font-size: 14px;
				font-size: calc-rem-value(14);
				color: #cdced0;
			}

			a:hover {
				text-decoration: underline;
			}
		}

	}
}