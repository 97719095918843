/*--------------------------------------------------------------
	#home-style-2
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#2.1	about-section-s2
--------------------------------------------------------------*/
.about-section-s2 {
	padding-bottom: 80px;

	@include media-query(991px) {
		padding-bottom: 70px;
	}

	@include media-query(767px) {
		padding-bottom: 60px;
	}

	.right-col {
		margin: 0 -15px;

		@include media-query(767px) {
			margin: 0 -7.5px;
		}

		> div {
			width: calc(50% - 30px);
			float: left;
			margin: 0 15px 30px;

			@include media-query(767px) {
				width: calc(50% - 15px);
				margin: 0 7.5px 30px;
			}
		}

		> div:last-child {
			@include widther(1200px) {
				padding-top: 45px;
			}
		}
	}

	.section-title-s5 {
		@include widther(1200px) {
			padding-right: 45px;
			padding-top: 65px;
		}
	}
}


/*--------------------------------------------------------------
#2.2	service-section-s2
--------------------------------------------------------------*/
.service-section-s2 {
	padding: 0 0 80px;

	@include media-query(991px) {
		padding-bottom: 70px;
	}

	@include media-query(767px) {
		padding-bottom: 60px;
	}

	.service-grids {
		margin: 0 -15px;

		@include media-query(767px) {
			margin: 0 -7.5px;
		}

		.grid {
			background: $white;
			width: calc(16.66% + 79px);
			float: left;
			text-align: center;
			margin: 0 15px 30px;
			padding: 30px 10px 35px;
			box-shadow: 0px 9px 42.32px 3.68px rgba(25, 38, 62, 0.11);
			@include transition-time(0.3s);

			@include media-query(1199px) {
				width: calc(33.33% - 30px);
			}

			@include media-query(767px) {
				width: calc(33.33% - 15px);
				margin: 0 7.5px 30px;
			}

			@include media-query(600px) {
				width: calc(50% - 15px);
			}
		}
		
		.grid:hover {
			background-color: $theme-primary-color;

			.fi:before,
			h3 a {
				color: $white;
			}
		}

		.fi:before {
			font-size: 50px;
			color: $theme-primary-color;
			margin-bottom: 0.9em;
		}

		h3 {
			font-size: 17px;
			font-size: calc-rem-value(17);
			font-weight: 400;
			margin: 0;

			@include media-query(600px) {
				font-size: 15px;
				font-size: calc-rem-value(15);
			}
		}

		h3 a {
			color: $heading-color;
		}
	}
}

.service-pg-service-section-s2 {
	padding-top: 100px;

	@include media-query(991px) {
		padding-top: 90px;
	}

	@include media-query(767px) {
		padding-top: 80px;
	}
}


/*--------------------------------------------------------------
#2.3	case-studies-section-s2
--------------------------------------------------------------*/
.case-studies-section-s2 {
	padding: 0;

	.case-studies-grids .grid {
		position: relative;

		.overlay {
			background: transparent;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			@include transition-time(0.5s);
		}

		&:hover {
			cursor: grab;
		}

		&:hover .overlay {
			background: $theme-secondary-color;
			background-image: -moz-linear-gradient( 90deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
			background-image: -webkit-linear-gradient( 90deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
			background-image: -ms-linear-gradient( 90deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
			opacity: 0.9;
			top: 0;

			p {
				max-height: 400px;
				opacity: 1;
				visibility: visible;
				transition: max-height 2s;
			}
		}

		.content {
			width: 100%;
			padding: 45px;
			position: absolute;
			left: 0;
			bottom: 0;

			@include media-query(991px) {
		    	padding: 35px;
		    }

			@include media-query(767px) {
		    	padding: 25px;
		    }
		}

		.cat {
			font-size: 14px;
			font-size: calc-rem-value(14);
			color: $theme-primary-color;
			font-weight: 700;
			margin: 0;
		}

		h3 {
			font-size: 20px;
			font-size: calc-rem-value(20);
			margin: 0.5em 0 0.9em;

			@include media-query(991px) {
		    	font-size: 18px;
		    	font-size: calc-rem-value(18);
		    }
		}

		h3 a {
			color: $white;
		}

		h3 a:hover {
			color: $theme-primary-color;
		}

		p {
			color: $white;
			transition: opacity 0.4s, visibility 0.4s, max-height 0.6s, margin 0.4s;
		    max-height: 0;
		    opacity: 0;
		    visibility: hidden;
		    overflow: hidden;

		    @include media-query(991px) {
		    	font-size: 13px;
		    	font-size: calc-rem-value(13);
		    }
		}

		p + a {
			font-size: 25px;
			font-size: calc-rem-value(25);
			color: $white;
		}
	}
}

.case-studies-pg-s2 {
	padding: 110px 0 100px;

	@include media-query(991px) {
		padding: 100px 0 90px;
	}

	@include media-query(767px) {
		padding: 80px 0 70px;
	}
}


/*--------------------------------------------------------------
#2.4	fun-fact-section
--------------------------------------------------------------*/
.fun-fact-section {

	.fun-fact-grids {
		padding: 65px 35px;

		@include media-query(1199px) {
			padding-bottom: 25px;
		}

		.grid {
			width: 25%;
			float: left;
			@include transition-time(0.3s);
			position: relative;
			top: 0;

			@include media-query(1199px) {
				width: 50%;
				border-bottom: 1px solid transparentize($white, 0.8);
				margin-bottom: 40px;
			}

			@include media-query(767px) {
				text-align: center;
			}

			@include media-query(600px) {
				width: 100%;
				float: none;
				text-align: center;
			}
		}

		h3 {
			font-size: 48px;
			font-size: calc-rem-value(48);
			color: $theme-primary-color;
			margin: 0;
			display: inline-block;

			@include media-query(767px) {
				font-size: 30px;
				font-size: calc-rem-value(30);
				display: block;
			}
		}

		p {
			font-size: 14px;
			font-size: calc-rem-value(14);
			font-weight: 700;
			color: $heading-color;
			text-transform: uppercase;
			display: inline-block;
			margin: 0;
			max-width: 85px;

			@include media-query(767px) {
				font-size: 12px;
				font-size: calc-rem-value(12);
				max-width: 100%;
			}

			@include media-query(600px) {
				position: relative;
				top: 8px;
			}
		}
	}
}

.fun-fact-section-s2 {
	background: #f1f1f1;
    margin-top: -10px;
}


/*--------------------------------------------------------------
#2.5	testimonials-section-s2
--------------------------------------------------------------*/
.testimonials-section-s2 {
	background: $theme-secondary-color;
	position: relative;

	&:before {
		content: "";
		@include background-style("../../images/testimonials/testimonials-bg.png", center center, 100%, no-repeat, local);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.grid .fi:before {
		font-size: 60px;
		color: $theme-primary-color;
	}

	.grid i {
		margin-top: -1.3em;
		display: block;
	}

	.grid p {
		font-family: $heading-font;
		font-size: 17px;
		font-size: calc-rem-value(17);
		color: $white;
		margin: 0;
	}

	.grid img {
		width: auto;
	}

	.testimonials-grids {
		@include widther(1200px) {
			padding-left: 40px;
		}
	}

	.client-info {
		position: relative;
		padding-left: 90px;
		padding-top: 15px;
		min-height: 70px;
		margin-top: 35px;

		.img-holder {
			position: absolute;
			left: 0;
			top: 0;

			img {
				border-radius: 50%;
			}
		}

		h3 {
			font-size: 16px;
			font-size: calc-rem-value(16);
			font-weight: 400;
			color: $theme-primary-color;
			margin: 0 0 0.2em;
		}

		h3 + span {
			font-size: 14px;
			font-size: calc-rem-value(14);
			color: $white;
		}
	}

	.slick-slider {
		margin: -15px;

		.slick-slide {
			padding: 15px;
		}
	}

	.slick-dots {
		text-align: center;
		bottom: -35px;
	}

	.slick-dots {
		li {
			width: unset;
			height: unset;

			button {
				width: 8px;
				background: $section-bg-color;
				margin-right: 5px;
				height: 8px;
				padding: 0;
				border-radius: 10px;

				&::before {
					display: none;
				}
			}

			&.slick-active {
				button {
					background: $theme-primary-color;
					width: 20px;
				}
			}
		}
	}	
}


/*--------------------------------------------------------------
#2.6	cta-section-s2
--------------------------------------------------------------*/
.cta-section-s2 {
	@include background-style("../../images/cta-bg.jpg", center center, cover, no-repeat, local);
	padding: 115px 0;
	text-align: center;
	position: relative;
	margin: 0;

	@include media-query(991px) {
		padding: 100px 0;
	}

	@include media-query(767px) {
		padding: 80px 0;
	}

	&:before {
		content: "";
		background: $theme-primary-color;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0.98;
	}

	h5 {
		font-size: 18px;
		font-size: calc-rem-value(18);
		margin: 2em 0 0;
		color: $white;
	}

	h2 {
		font-size: 36px;
		font-size: calc-rem-value(36);
		margin: 0.5em 0 1em;
		color: $white;
	}
}


/*--------------------------------------------------------------
#2.7	contact-section-s2
--------------------------------------------------------------*/
.contact-section-s2 {
	@extend .contact-section;

	@include widther(1450px) {
		margin: -219px 0 0;
	}

	&:before {
		background: $theme-secondary-color;
	}
}


/*--------------------------------------------------------------
#2.8	partners-section
--------------------------------------------------------------*/
.partners-section {
	background-color: #f9f9f9;
	padding: 70px 0 58px;

	.grid {
		text-align: center;
	}

	.grid img {
		width: auto;
		margin: 0 auto;
		display: inline-block;
	}
}
