/*--------------------------------------
	service single sidebar
----------------------------------------*/
.service-sidebar {
	@include media-query(991px) {
		max-width: 300px;
		margin-top: 80px;
	}
	> .widget + .widget {
		margin-top: 60px;
	}

	/*** service-list-widget ***/
	.service-list-widget {
		ul {
			list-style: none;
			border: 1px solid #eaeaea;
			border-bottom: 0;
		}

		ul li {
			border-bottom: 1px solid #eaeaea;
		}

		a {
			font-size: 15px;
			font-size: calc-rem-value(15);
			background: #fcfcfc;
			font-weight: 600;
			display: block;
			color: $heading-color;
			padding: 16px 24px;
		}

		a:hover,
		.current a {
			background-color: $theme-primary-color;
			color: $white;
		}
	}


	/*** download-widget ***/
	.download-widget {
		ul {
			list-style: none;
		}

		ul > li + li {
			margin-top: 5px;
		}

		ul a {
			background: $theme-secondary-color;
			color: $white;
			padding: 18px 25px;
			font-weight: 700;
			display: block;
		}

		ul i {
			display: inline-block;
			padding-right: 10px;
			color: $white;
		}

		ul a:hover {
			background: darken($theme-primary-color, 10%);
		}
	}

	/*** contact-widget ***/
	.contact-widget {
		@include background-style("../../images/single-contact-widget-bg.jpg", center center, cover, no-repeat, local);
		padding: 45px 40px;
		position: relative;

		&:before {
			content: "";
			@include overlay(transparentize($theme-secondary-color, 0.1));
		}

		> div {
			position: relative;
			z-index: 1;
		}

		h4 {
			font-size: 20px;
			font-size: calc-rem-value(20);
			color: $white;
			font-weight: 400;
			margin: 0 0 0.8em;
			text-transform: capitalize;
		}

		p {
			color: $white;
			font-size: 14px;
			font-size: calc-rem-value(14);
		}

		ul li {
			font-size: 14px;
			font-size: calc-rem-value(14);
			position: relative;
			padding-left: 25px;

			i {
				position: absolute;
				left: 0;
				top: 3px;
				color: $theme-primary-color;
			}
		}

		ul > li + li {
			margin-top: 12px;
		}

		a {
			background-color: $white;
			font-size: 14px;
			font-size: calc-rem-value(14);
			font-weight: 600;
			color: $text-color;
			padding: 7px 15px;
			display: inline-block;
			margin-top: 15px;
		}

		a:hover {
			color: $theme-primary-color;
		}
	}


	/*** service-features-widget ***/
	.service-features-widget {
		padding: 45px 30px;
		position: relative;
		border: 2px solid #f7f7f7;

		&:before {
			font-family: "Themify";
			content: "\e61f";
			font-size: 25px;
			font-size: calc-rem-value(25);
			color: $theme-primary-color;
			position: absolute;
			right: 30px;
			top: 40px;
			opacity: 0.5;
		}

		h3 {
			font-size: 18px;
			font-size: calc-rem-value(18);
			margin: 0 0 0.8em;
			text-transform: capitalize;
		}	

		ol {
			list-style-position: inside;
			padding-left: 0;
			margin: 0;
		}	

		ol > li + li {
			margin-top: 8px;
		}
	}
}