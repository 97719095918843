/*--------------------------------------------------------------
	#contact page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#8.1	contact-pg-section
--------------------------------------------------------------*/
.contact-pg-section {
	padding-bottom: 35px;

	@include media-query(991px) {
		padding-bottom: 15px;
	}
}

.contact-map-section {

	.contact-map {
	    height: 450px;
	}

    iframe {
	    width: 100%;
	    height: 100%;
	    border: 0;
	    outline: 0;
	}
}