/*--------------------------------------------------------------
	#blog page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#9.1	blog-pg-section
--------------------------------------------------------------*/
.blog-pg-section {
	.blog-content {

		.post {
			margin-bottom: 100px;

			@include media-query(991px) {
				margin-bottom: 70px;
			}

			@include media-query(767px) {
				margin-bottom: 60px;
			}
		}
	}

	.entry-details {
		border: 2px solid #f4f4f4;
		border-top: 0;
		padding: 50px 35px 35px;
		position: relative;
		z-index: 1;

		@include media-query(500px) {
			padding: 50px 20px 35px;
		}

		.cat {
			background: $theme-primary-color;
			font-size: 13px;
			font-weight: 700;
			padding: 10px 15px;
			color: white;
			position: absolute;
			left: -2px;
			top: -20px;
			border-radius: 0 50px 50px 0;
		}

		.read-more {
			font-weight: 700;
			color: $theme-primary-color;
		}
	}

	.entry-meta {
		border: 2px solid #f4f4f4;
		border-top: 0;
		padding: 15px 35px;

		@include media-query(500px) {
			padding: 15px 20px;
		}

		span {
			font-size: 14px;
			font-size: calc-rem-value(14);
			font-weight: 600;
			color: $text-color;
			display: inline-block;
		}

		>span+span {
			margin-left: 20px;
		}

		span a {
			color: $text-color;
		}

		span a:hover {
			color: $theme-primary-color;
		}
	}

	.post {
		h3 {
			font-size: 28px;
			font-size: calc-rem-value(28);
			line-height: 1.2em;
			margin: -0.27em 0 0.7em;

			@include media-query(991px) {
				font-size: 25px;
				font-size: calc-rem-value(25);
			}

			@include media-query(767px) {
				font-size: 22px;
				font-size: calc-rem-value(22);
			}
		}

		h3 a {
			color: $heading-color;
		}

		h3 a:hover {
			color: $theme-primary-color;
		}

		p {
			margin-bottom: 1.8em;

			@include media-query(991px) {
				font-size: 16px;
				font-size: calc-rem-value(16);
			}
		}
	}

	/*** format-standard ***/
	.format-standard,
	.format-quote {
		.entry-details {
			border-top: 2px solid #f4f4f4;
			padding: 55px 35px 35px;

			@include media-query(500px) {
				padding: 35px 20px 35px;
			}

			.date {
				position: static;
				display: inline-block;
				margin-bottom: 25px;
			}

			.read-more {
				font-weight: 700;
				color: $theme-primary-color;
			}
		}

	}

	/*** format-gallery ***/
	.format-gallery {
		position: relative;

		.owl-controls {
			width: 100%;
			margin: 0;
			position: absolute;
			left: 0;
			top: 50%;
			@include translatingY();
		}

		.owl-controls .owl-nav [class*=owl-]:hover {
			background: $theme-primary-color;
		}

		.owl-controls .owl-nav [class*=owl-] {
			background: transparentize(#858585, 0.5);
			width: 50px;
			height: 50px;
			line-height: 50px;
			padding: 0;
			margin: 0;
			border-radius: 50%;
			@include transition-time(0.3s);
		}

		.owl-controls .owl-nav .owl-prev,
		.owl-controls .owl-nav .owl-next {
			position: absolute;
			top: 50%;
			@include translatingY();
		}

		.owl-controls .owl-nav .owl-prev {
			left: 15px;
		}

		.owl-controls .owl-nav .owl-next {
			right: 15px;
		}
	}

	/*** format-quote ***/
	.format-quote {
		background-color: #f9f9f9;
		position: relative;

		&:before {
			font-family: "Flaticon";
			content: "\f105";
			font-size: 200px;
			font-size: calc-rem-value(200);
			color: #ecf3fb;
			margin-left: 0;
			position: absolute;
			left: 50%;
			top: 50%;
			@include center-by-translating();
		}

		h3,
		p {
			position: relative;
		}
	}

	/*** format-video ***/
	.format-video .entry-media {
		position: relative;
		text-align: center;

		&:before {
			content: "";
			background-color: #233d62;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			@include transition-time(0.3s);
			opacity: 0.5;
		}

		&:hover:before {
			opacity: 0.7;
		}


		button.video-btn {
			position: absolute;
			left: 50%;
			top: 50%;
			@include center-by-translating();
			background-color: #fff;
			width: 65px;
			height: 65px;
			line-height: 65px;
			text-align: center;
			border-radius: 65px;
			display: inline-block;
			border: 0;

			&::after {
				content: "";
				width: 0px;
				height: 0px;
				border-top: 10px solid transparent;
				border-bottom: 10px solid transparent;
				border-left: 10px solid #c9b38c;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.fi:before {
			font-size: 80px;
			font-size: calc-rem-value(80);
			color: $white;

			@include media-query(767px) {
				font-size: 60px;
				font-size: calc-rem-value(60);
			}
		}
	}
}

.blog-pg-left-sidebar {
	.blog-sidebar {
		@include widther(1200px) {
			padding-right: 45px;
			padding-left: 0;
		}
	}
}

.blog-pg-fullwidth {
	.blog-content {
		@include widther(1200px) {
			padding: 0;
		}
	}
}


.blog-pg-section .format-standard-image button.video-btn {
	display: none;
}


@media (min-width: 992px) {
	.order-lg-1 .blog-sidebar {
		padding-left: 0;
		padding-right: 30px;
	}
}