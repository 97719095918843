	/*
  	Flaticon icon font: Flaticon
  	Creation date: 07/01/2020 03:45
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-next:before { content: "\f100"; }
.flaticon-back:before { content: "\f101"; }
.flaticon-back-1:before { content: "\f102"; }
.flaticon-next-1:before { content: "\f103"; }
.flaticon-left-quote:before { content: "\f104"; }
.flaticon-blocks-with-angled-cuts:before { content: "\f105"; }
.flaticon-play-button:before { content: "\f106"; }
.flaticon-diamond:before { content: "\f107"; }
.flaticon-light-bulb:before { content: "\f108"; }
.flaticon-standard:before { content: "\f109"; }
.flaticon-suitcase:before { content: "\f10a"; }
.flaticon-basket:before { content: "\f10b"; }
.flaticon-shopping-basket:before { content: "\f10c"; }
.flaticon-magnifying-glass:before { content: "\f10d"; }
.flaticon-dog:before { content: "\f10e"; }
.flaticon-wounded:before { content: "\f10f"; }
.flaticon-thief:before { content: "\f110"; }
.flaticon-coin:before { content: "\f111"; }
.flaticon-save-money:before { content: "\f112"; }
.flaticon-parents:before { content: "\f113"; }
.flaticon-mortarboard:before { content: "\f114"; }
.flaticon-architecture-and-city:before { content: "\f115"; }
.flaticon-home:before { content: "\f116"; }
.flaticon-home-1:before { content: "\f117"; }
.flaticon-home-2:before { content: "\f118"; }
.flaticon-architecture-and-city-1:before { content: "\f119"; }
.flaticon-home-3:before { content: "\f11a"; }
.flaticon-email:before { content: "\f11b"; }
.flaticon-support:before { content: "\f11c"; }
.flaticon-clock:before { content: "\f11d"; }
.flaticon-paper-plane:before { content: "\f11e"; }
.flaticon-courthouse:before { content: "\f11f"; }
.flaticon-balance:before { content: "\f120"; }
.flaticon-courthouse-1:before { content: "\f121"; }
.flaticon-mace:before { content: "\f122"; }