/*--------------------------------------------------------------
	#project single page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#6.1	project-single-section
--------------------------------------------------------------*/
.project-single-section {

	.img-holder {
		img {
			width: 100%;
		}
	}
	.project-details {
		@include widther(1200px) {
			padding: 60px 0 0 15px;
		}

		h2 {
			font-size: 36px;
			font-size: calc-rem-value(36);
			margin: 0 0 0.6em;

			@include media-query(991px) {
				font-size: 30px;
				font-size: calc-rem-value(30);
				margin: 1.4em 0 0.6em;
			}

			@include media-query(767px) {
				font-size: 26px;
				font-size: calc-rem-value(26);
			}
		}

		h3 {
			font-size: 20px;
			font-size: calc-rem-value(20);
			margin: 0 0 1em;
			line-height: 1.4em;
		}

		p {
			margin-bottom: 1.5em;
		}
	}

	/*** project single tab ***/
	.project-single-tab {
		margin: 70px 0;
		clear: both;

		@include media-query(991px) {
			margin: 40px 0;
		}
	}

	.project-single-tab .nav {
		overflow: hidden;
		margin: 0;

		li {
			float: left;
			padding-left: 0;
		}

		> li + li {
			margin-top: 0;
			margin-left: 3px;
			@include media-query(767px) {
				margin-left: 1px;
			}
		}

		a {
			background: $white;
			font-size: 16px;
			font-size: calc_rem_value(16);
			font-weight: bold;
			color: $heading-color;
			padding: 13px 25px;
			border: 1px solid transparentize($theme-secondary-color, 0.8);
			cursor: pointer;

			@include media-query(767px) {
				font-size: 13px;
				font-size: calc_rem_value(13);
				padding: 8px 12px;
				text-transform: none;
			}

			@include media-query(350px) {
				padding: 8px;
			}
		}

		.active,
		a:hover {
			background: $theme-secondary-color;
			color: $white;
		}
	}

	.tab-content {
		padding: 35px 40px;
		border: 1px solid transparentize($theme-secondary-color, 0.8);
		margin-top: -1px;


		@include media-query(767px) {
			padding: 25px 20px;
		}

		.tab-pane p {
			margin-bottom: 20px;

			@include media-query(767px) {
				margin-bottom: 15px;
			}
		}

		.tab-pane p:last-child {
			margin-bottom: 0;
		}
	}

	.related-case-studies h2 {
		font-size: 36px;
		font-size: calc-rem-value(36);
		margin: 0.3em 0 1em;

		@include media-query(991px) {
			font-size: 30px;
			font-size: calc-rem-value(30);
		}
	}

	.related-case-studies .grid {
		position: relative;
		text-align: center;
		width: calc(33.33%);
		float: left;
		overflow: hidden;

		@include media-query(767px) {
			width: calc(50%);
		}

		@include media-query(600px) {
			width: 100%;
			float: none;

			img {
				width: 100%;
			}
		}

		.overlay {
			background: $theme-secondary-color;
			background-image: -moz-linear-gradient( 90deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
			background-image: -webkit-linear-gradient( 90deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
			background-image: -ms-linear-gradient( 90deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 50%;
			opacity: 0;
			@include transition-time(0.5s);
		}

		&:hover {
			cursor: grab;
		}

		&:hover .overlay {
			top: 0;
			opacity: 0.9;
		}

		.content {
			width: 100%;
			position: absolute;
			left: 0;
			top: 50%;
			@include translatingY();
		}

		.cat {
			font-size: 14px;
			font-size: calc-rem-value(14);
			color: $theme-primary-color;
			font-weight: 700;
			margin: 0;
		}

		h3 {
			font-size: 20px;
			font-size: calc-rem-value(20);
			margin: 0;
		}

		h3 a {
			color: $white;
		}

		h3 a:hover {
			color: $theme-primary-color;
		}
	}
}