/*--------------------------------------------------------------
	#shop page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#11.1  shop-pg-section
--------------------------------------------------------------*/
.shop-pg-section {

	.shop-grids {
		margin: 0 -15px;
	}

	.shop-grids .grid {
		width: calc(33.33% - 30px);
		float: left;
		margin: 0 15px 30px;

		@include media-query(991px) {
			width: calc(50% - 30px);
		}

		@include media-query(550px) {
			width: calc(100% - 30px);
			float: none;
		}
	}
	
	.grid:hover .cart-details li {
		opacity: 1;
		visibility: visible;
		bottom: 0;
	}

	.img-cart {
		position: relative;
		overflow: hidden;
	}

	.cart-details {
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 20px;
		text-align: center;
	}

	.cart-details ul {
		overflow: hidden;
		display: inline-block;
		list-style-type: none;
	}

	.cart-details li {
		float: left;
		margin: 7px;
		position: relative;
		bottom: -50px;
		opacity: 0;
		visibility: hidden;
		@include transition-time(0.5s);
	}

	.cart-details li:last-child {
		-webkit-transition: all 0.5s 0.1s;
		transition: all 0.5s 0.1s;
	}

	.details {
		text-align: center;
		padding: 30px 15px;

		h4 {
			font-size: 18px;
			font-size: calc-rem-value(18);
			font-weight: 400;
			margin: 0 0 0.4em;

			@include media-query(991px) {
				font-size: 16px;
				font-size: calc-rem-value(16);
			}
		}

		h4 a {
			color: $heading-color;
		}

		h4 a:hover {
			color: $theme-primary-color;
		}

		del {
		    color: #aaa;
			font-weight: 500;
		    display: inline-block;
		    padding-right: 10px;

		    @include media-query(991px) {
				font-size: 14px;
				font-size: calc-rem-value(14);
			}
		}

		.price {
			color: $heading-color;
			font-weight: 500;

			@include media-query(991px) {
				font-size: 14px;
				font-size: calc-rem-value(14);
			}
		}
	}

	.pagination-wrapper {
		text-align: center;
		clear: both;
	}
}